.home-page-welcome {
  position: relative;
  padding: 50px 0;
  background-size: cover;
  z-index: 1;
}

.welcome-content .entry-title {
  position: relative;
  padding-bottom: 24px;
  font-size: 40px;
  font-weight: bold;
  color: #0011A7;
  text-align: center;
  padding: 0px;
  line-height: 50px;
  border-radius: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 0 auto;
}

.welcome-content .entry-content {
  font-size: 20px;
  line-height: 2;
  color: #262626;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
}

.home-page-welcome::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(250, 250, 250, 0.8);
}

.quote {
  font-style: italic;
  color: #cd2653;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 20px !important;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }
}

.containernewbox {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  border-left: 5px solid #cd2653;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.quotehead{
  text-align: center;
  font-size: 25px;
  line-height: 50px;
}